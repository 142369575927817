import React, { useState } from 'react';
import GenerateIndustryListButton from './GenerateIndustryListButton';
import useIndustryList from './useIndustryList'; // Import the correct hook for industries
import IndustryComponent from './industryComponent';
import { useSelector, useDispatch } from 'react-redux'; // Import useSelector hook
import { ShoppingCart } from '@mui/icons-material';
import { setMainContent } from '../../../redux/workAreaSlice'; // Import the action from your Redux slice

const IndustryListContent = ({
  userId,
  caseId,
  caseName,
  handleNavigateToProspect,
}) => {
  console.log('IndustryListContent', userId, caseId, caseName);
  const { isFetching } = useIndustryList(userId, caseId); // Update hook function and variable name
  const industries = useSelector((state) => state.industry.industries);
  const dispatch = useDispatch(); // Add this line to get the dispatch function

  const [feedbackMessage, setFeedbackMessage] = useState(''); // Add state for feedback message

  const handleShowCart = () => {
    dispatch(setMainContent('ShowCart')); // Dispatch the action to update mainContent to 'ShowCart'

    // Show feedback message when the cart button is clicked
    setFeedbackMessage('Item(s) added to cart');
    setTimeout(() => {
      setFeedbackMessage(''); // Clear the feedback message after a few seconds
    }, 3000); // Adjust the duration as needed
  };

  console.log('industries', industries);
  return (
    <div className="px-4 py-2">
      {isFetching ? (
        <p>Loading industry list...</p> // Update loading message
      ) : Object.keys(industries).length === 0 ? ( // Update condition
        <div className="text-center px-4 py-2">
          <p className="py-2">No industry list found for the selected case.</p>
          <GenerateIndustryListButton userId={userId} caseId={caseId} />{' '}
        </div>
      ) : (
        <div className="px-4 py-2">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2>{caseName}</h2>
              <p className="text-sm text-gray-500">
                {Object.keys(industries).length} industries found
              </p>
            </div>
            <div>
              <div className="flex ">
                <GenerateIndustryListButton userId={userId} caseId={caseId} />
                <button
                  className="bg-blue-500 text-white py-2 px-4 mr-4 rounded"
                  onClick={handleNavigateToProspect}
                >
                  {feedbackMessage && (
                    <div className="text-green-600">{feedbackMessage}</div>
                  )}
                  <ShoppingCart onClick={handleShowCart} />
                </button>
              </div>
            </div>
          </div>

          <IndustryComponent industries={industries} />
        </div>
      )}
    </div>
  );
};

export default IndustryListContent; // Export the updated component
