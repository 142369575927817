import {
  LightningBoltIcon,
  SearchCircleIcon,
  MailIcon,
  UserGroupIcon,
  LightBulbIcon,
} from '@heroicons/react/solid';
import React from 'react';
import Feature from './FeatureItem';
// import LoginButton from "../auth0/LoginButton";
import { useTranslation } from 'react-i18next';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

const Features = (props) => {
  const { t } = useTranslation();
  const { handleSignUp } = props;
  return (
    <section className="container mt-24 flex flex-col items-center">
      <h2 className="text-[32px] font-bold text-center sm:text-left">
        {t('feature.title')}
      </h2>

      <div className="mt-12 grid sm:grid-cols-2 lg:grid-cols-4 gap-12">
        <Feature
          Icon={LightningBoltIcon}
          iconBgColor="#4D8DFF"
          title={t('feature.input.name')}
          description={t('feature.input.description')}
        />

        <Feature
          Icon={SearchCircleIcon}
          title={t('feature.scan.name')}
          iconBgColor="#02897A"
          description={t('feature.scan.description')}
        />

        <Feature
          Icon={LightBulbIcon}
          iconBgColor="#800080"
          title={t('feature.plan.name')}
          description={t('feature.plan.description')}
        />
        <Feature
          Icon={UserGroupIcon}
          iconBgColor="#F03E3D"
          title="Web-Sourced Repository"
          description="Powered by 1B+ leads, RFPs and updates"
        />
      </div>
      {/* <div className="primary-button mt-14" onClick={handleSignUp}>
        <LoginButton text="Sign up Now"/> 
        {t('feature.action')}
      </div> */}
    </section>
  );
};

export default Features;
