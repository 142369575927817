import { useState } from 'react';
import PendingSpinner from './CaseFormPending';
import CaseFormInpput from './CaseFormInput';

const CaseForm = () => {
  const [submitStatus, setSubmitStatus] = useState('NEW');

  const FormSubmitted = (
    <div>
      <p>Your case has been submitted successfully!</p>
      <button onClick={() => setSubmitStatus('NEW')}>Create New Case</button>
    </div>
  );

  const handleBeforeCaseSubmit = () => setSubmitStatus('PENDING');

  const handleAfterCaseSubmit = () => setSubmitStatus('SUBMITTED');

  return (
    (submitStatus === 'NEW' && (
      <CaseFormInpput
        handleBeforeCaseSubmit={handleBeforeCaseSubmit}
        handleAfterCaseSubmit={handleAfterCaseSubmit}
      />
    )) ||
    (submitStatus === 'PENDING' && <PendingSpinner />) ||
    (submitStatus === 'SUBMITTED' && FormSubmitted)
  );
};

export default CaseForm;
