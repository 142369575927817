import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../redux/cartSlice'; // Import the addToCart action
import axios from 'axios'; // Import Axios for making the POST request to start the email
import config from '../../../Constant';

const PersonCard = ({ person, title, userId, caseId, prospectId }) => {
  const dispatch = useDispatch();
  const [isDraftingEmail, setIsDraftingEmail] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);

  // Local state to store email data
  const [emailData, setEmailData] = useState({
    email_content: '',
    email_subject: '',
  });

  // Check if email content already exists for the user and prospect
  useEffect(() => {
    const fetchEmailContent = async () => {
      try {
        const response = await axios.get(
          `${config.PROSPECT.GET_EMAIL_URI}?userId=${userId}&prospectId=${prospectId}&caseId=${caseId}&receipientEmail=${person.email}`
        );

        if (response.data && response.data.email_content) {
          setEmailData({
            email_content: response.data.email_content,
            email_subject: response.data.email_subject,
          });
        }
      } catch (error) {
        console.error('Error fetching email content:', error);
      }
    };

    fetchEmailContent();
  }, []);

  const handleAddToCart = async () => {
    // Create an object with email as key and person as value
    const cartItem = {
      [prospectId]: {
        person,
        title,
        email_content: emailData.email_content,
        email_subject: emailData.email_subject,
      },
    };

    // Save the cart item to DynamoDB
    try {
      await axios.post(config.PROSPECT.SAVE_CART_ITEM_URI, {
        userId,
        caseId,
        prospectId,
        cartItem,
      });
      console.log('Cart item saved to DynamoDB successfully.');
    } catch (error) {
      console.error('Error saving cart item to DynamoDB:', error);
      // Handle error as needed
    }

    // Dispatch the addToCart action with the cartItem object
    dispatch(addToCart(cartItem));
  };

  const handleEmailClick = async () => {
    if (emailData.email_content == '') {
      await initiateEmail();
      return;
    }

    setIsEmailVisible(!isEmailVisible);
  };

  const initiateEmail = async () => {
    setIsDraftingEmail(true);

    // Define the data to send in the POST request (adjust the data structure as needed)
    const emailData = {
      action: 'new', // Or another appropriate action value
      caseId: caseId, // You may need to pass caseId as a prop or from your component's state
      userId: userId, // You may need to pass userId as a prop or from your component's state
      customerInfoUrl:
        person.organization.website_url || person.organization.linkedin_url, // Replace with the actual URL
      name: `${person.first_name} ${person.last_name}`,
      receipient_name: person.name,
      receipient_email: person.email,
      receipient_title: person.title,
      receipient_company: person.organization.name,
      sender_name: userId,
      sender_email: userId,
      sender_title: '',
      sender_company: '',
      prospectId,
      // [TODO]: we need a name card for everyone who is using the system
      // email: person.email,
      // Add other relevant data for email construction
    };

    try {
      // Make a POST request to your server endpoint to initiate the email
      const response = await axios.post(
        `${config.CASE.CONSTRUCT_EMAIL_URI}`,
        emailData
      );

      // Handle the response, e.g., show a success message
      console.log('Email initiated:', response.data);

      // Update the local state with email data
      setEmailData({
        email_content: response.data.email_content,
        email_subject: response.data.email_subject,
      });
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Error initiating email:', error);
    } finally {
      setIsDraftingEmail(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden mb-4">
      <img
        src={person.photo_url}
        alt={`${person.first_name} ${person.last_name}`}
        className="w-1/2 h-auto object-cover m-4"
      />
      <div className="px-6 py-4">
        <h2 className="text-xl font-semibold">{`${person.first_name} ${person.last_name}`}</h2>
        <p className="text-gray-600">{person.title}</p>
        <p className="text-blue-600">
          {person.linkedin_url && (
            <a
              href={person.linkedin_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          )}
        </p>
        <p>Email: {person.email}</p>
      </div>
      <div className="px-6 py-2 bg-gray-100">
        <p className="text-sm font-semibold">Organization:</p>
        <p>{person.organization.name}</p>
        {person.organization.sanitized_phone && (
          <p>Phone: {person.organization.sanitized_phone}</p>
        )}
        <div className="flex justify-between mt-2">
          {person.organization.linkedin_url && (
            <a
              href={person.organization.linkedin_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              LinkedIn (Organization)
            </a>
          )}
          {person.organization.facebook_url && (
            <a
              href={person.organization.facebook_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Facebook (Organization)
            </a>
          )}
          {person.organization.website_url && (
            <a
              href={person.organization.website_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Website (Organization)
            </a>
          )}
        </div>
      </div>

      <div className="px-6 py-4 flex space-x-2">
        <button
          className={`w-full ${
            isDraftingEmail ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
          } text-white px-4 py-2 rounded-full hover:bg-red-600`}
          onClick={handleEmailClick}
          disabled={isDraftingEmail}
        >
          {isDraftingEmail
            ? 'Drafting...'
            : emailData.email_content
            ? isEmailVisible
              ? 'Hide Email'
              : 'Show Email'
            : 'Draft Email'}
        </button>
        <button
          className="w-full bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600"
          onClick={handleAddToCart}
        >
          Add to Cart
        </button>
        <button
          className="w-full bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600"
          onClick={() => {
            // Handle more details action
          }}
        >
          More Details
        </button>
      </div>
      <div>
        {/* Email content (hidden by default) */}
        {isEmailVisible && (
          <div className="px-6 py-4">
            <h3 className="text-lg font-semibold mb-2">
              Email Subject: {emailData.email_subject}
            </h3>
            <p className="whitespace-pre-line">{emailData.email_content}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonCard;
