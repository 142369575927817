import React from 'react';

function Feature({ Icon, iconBgColor, title, description }) {
  return (
    <div className="flex flex-col text-center sm:text-left">
      <div
        style={{ background: `${iconBgColor}` }}
        className="mx-auto h-12 w-12 rounded-[19px] flex justify-center items-center drop-shadow-md sm:ml-0"
      >
        <Icon className="h-6 mb-1 text-white" />
      </div>
      <h4 className="mt-6 h-15 font-semibold text-2xl">{title}</h4>
      <p className="h-12 text-base mt-2">{description}</p>
    </div>
  );
}

export default Feature;
