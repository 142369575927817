import { PlayIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlayVideo from './PlayVideo';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

const Hero = (props) => {
  const { handleGetItClick } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleVideoClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    // parent div https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pngegg.com%2Fen%2Fsearch%3Fq%3Drelax%2BBeach&psig=AOvVaw2P9FeBMQcdGCFhH5LYU5wy&ust=1680915410882000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIi2uoHIlv4CFQAAAAAdAAAAABAF
    <main className="container mt-8 md:flex flex-row-reverse justify-between items-center">
      <div className="md:max-w-[50%] ">
        <img src="./images/front.png" alt="Hero" />
      </div>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          {/* Modal Content */}
          <PlayVideo videoSource="./images/front_video/output.m3u8" />
        </DialogContent>
      </Dialog>

      {/* text section */}
      <div className="text-center sm:text-left md:max-w-[40%]">
        <h1 className="font-bold text-4xl leading-[60px]">
          {'Prospecting CoPilot'}
        </h1>
        <div className="flex items-center mt-4 text-[18px] leading-[28px] font-normal">
          <p className="mr-2 mt-2">
            {'Enhanced by Generative AI with 1B+ Leads, RFPs and Updates'}
          </p>
        </div>
        <div className="mt-8 flex items-center justify-around sm:justify-start sm:space-x-8">
          <button className="primary-button" onClick={handleGetItClick}>
            {t('Get Started')}
          </button>
          <p
            onClick={handleVideoClick}
            className="font-semibold text-primary whitespace-nowrap flex items-center underline hover:scale-110 active:scale-95 duration-200 cursor-pointer"
          >
            <PlayIcon className="h-8" />
            {t('Watch the Video')}
          </p>
        </div>
      </div>
    </main>
  );
};

export default Hero;
