import React from "react";
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <div className="pt-12 pb-8">
      <div className="container mb-12 text-center grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="https://LeadPilot.Cloud/" className="hover:underline">LeadPilot.Cloud™</a>. All Rights Reserved.
        </span>

        <div>
          <a href="/term" className="mr-4 hover:underline md:mr-6 text-sm text-gray-500 dark:text-gray-400 ">Terms of Service</a>
        </div>

        <div>
          <Link to="/privacy" className="mr-4 hover:underline md:mr-6 text-sm text-gray-500 dark:text-gray-400 ">Privacy Policy</Link>
        </div>

      </div>

    </div>
  );
}

export default Footer;
