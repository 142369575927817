// redux/cartSlice.js

import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: {}, // { prospectId: [{ person, title }, ...], ... }
  },
  reducers: {
    addToCart: (state, action) => {
      const cartItem = action.payload;
      for (const prospectId in cartItem) {
        if (state.items[prospectId]) {
          // If the prospectId already exists, append to the existing array
          state.items[prospectId].push(cartItem[prospectId]);
        } else {
          // If it doesn't exist, create a new array
          state.items[prospectId] = [cartItem[prospectId]];
        }
      }
    },
    initializeCart: (state, action) => {
      const cartItems = action.payload;
      state.items = cartItems;
    },
    removeFromCart: (state, action) => {
      // Remove an item from the cart by index or ID, if needed
      // You can implement this logic if you want to remove items from the cart.
    },
    updateCartItemEmail: (state, action) => {
      const { prospectId, emailContent, emailSubject, personEmail } =
        action.payload;

      // Find the prospectId in the cart and update its email content and subject
      if (state.items[prospectId]) {
        state.items[prospectId] = state.items[prospectId].map((item) => {
          // Check if person's email matches and update email content and subject
          if (item.person?.email === personEmail) {
            return {
              ...item,
              email_content: emailContent,
              email_subject: emailSubject,
            };
          }
          return item;
        });
      }
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  initializeCart,
  updateCartItemEmail,
} = cartSlice.actions;
export default cartSlice.reducer;
