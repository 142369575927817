import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles
import { Delete, ChatBubble, ChatBubbleOutline } from '@material-ui/icons';
import ChatDialog from '../Chat/ChatDiag';
import GenerateProspectListBySegmentButton from './GenerateProspectListBySegmentButton'; // Import the button component
import { Close } from '@mui/icons-material'; // Import the Close icon

const useStyles = makeStyles((theme) => ({
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    fontSize: '1rem',
    width: '2rem',
    height: '2rem',
    cursor: 'pointer', // Add cursor pointer
    marginLeft: theme.spacing(1), // Add spacing between icons
    zIndex: 9999,
  },
  prospectIconButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    fontSize: '1rem',
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer', // Add cursor pointer
    marginLeft: theme.spacing(1), // Add spacing between icons
  },
  collapseExpandButton: {
    fontSize: '1.5rem', // Adjust the font size to make it smaller
    cursor: 'pointer', // Add cursor pointer
  },
}));

const IndustryComponent = ({ industries }) => {
  console.log(industries);
  const classes = useStyles(); // Initialize the styles
  const [currentActiveChatThread, setcurrentActiveChatThread] = useState(''); // State to manage chat dialog visibility
  // const dispatch = useDispatch(); // Initialize dispatch
  const [isChatExpanded, setIsChatExpanded] = useState(false); // State to manage chat expansion
  const [expandedAccordionPanels, setExpandedAccordionPanels] = useState([]); // State variable to track expanded panels

  const handleChatIconClick = (
    userId,
    caseId,
    event,
    id,
    title,
    target,
    segmentId,
    prospectId,
    companyDomain
  ) => {
    event.stopPropagation();
    const newChatThread = {
      id,
      title,
      target: target,
      history: [],
      userId,
      caseId,
      segmentId, // Add segmentId to the newChatThread
      prospectId, // Add prospectId to the newChatThread
      companyDomain,
    };

    // dispatch(addThread(newChatThread)); // Dispatch the action to add the new thread
    setcurrentActiveChatThread(newChatThread);
  };

  const handleDeleteIconClick = (event) => {
    // Implement industry deletion logic based on the industry name
    event.stopPropagation(); // Prevent Accordion expansion
  };

  const handleCloseChat = () => {
    setcurrentActiveChatThread({});
  };

  const handleToggleChat = () => {
    setIsChatExpanded(!isChatExpanded);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    // Update the expandedPanels state when an accordion is expanded/collapsed
    event.stopPropagation();
    console.log('accordion is changed');
    setExpandedAccordionPanels(isExpanded ? [panel] : []);
  };

  return (
    <div className="flex">
      <div
        className={`flex-grow ${
          Object.keys(currentActiveChatThread).length
            ? isChatExpanded
              ? 'hidden' // If chat is expanded, take no width
              : 'w-2/3' // If chat is not expanded, take 2/3 width
            : 'w-full'
        }`}
      >
        {Object.keys(industries).map((segmentId) => {
          const {
            industryName,
            reason: industryDetails,
            userId,
            caseId,
            prospects,
            prospectIds,
            /*
              prospectIds: ["prospect_fzhang0723@gmail.com_1693462062"]
              prospects: [{company_name: "FedEx", prospect_reason: ""}]
            */
          } = industries[segmentId];

          if (industryName && industryDetails) {
            return (
              <Accordion
                key={segmentId}
                className="mb-2"
                expanded={expandedAccordionPanels.includes(segmentId)} // Check if this panel should be expanded
                onChange={handleAccordionChange(segmentId)} // Update the state when accordion is expanded/collapsed
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="flex items-center w-full">
                    <Typography variant="h6">{industryName}</Typography>
                  </div>
                  <div className="flex items-center ml-auto space-x-5 mr-5">
                    <GenerateProspectListBySegmentButton
                      userId={userId}
                      caseId={caseId}
                      segmentId={segmentId}
                      fontSize="inherit"
                      className={classes.iconButton}
                    />
                    {/* {currentActiveChatThread.id === segmentId ? (
                      <ChatBubble
                        fontSize="inherit"
                        onClick={handleCloseChat}
                        style={{ zIndex: 9999 }}
                        className={classes.iconButton}
                      />
                    ) : (
                      <ChatBubbleOutline
                        fontSize="inherit"
                        onClick={(event) =>
                          handleChatIconClick(
                            userId,
                            caseId,
                            event,
                            segmentId,
                            industryName,
                            'segment',
                            segmentId
                          )
                        }
                        className={classes.iconButton}
                      />
                    )} */}
                    <Delete
                      fontSize="inherit"
                      onClick={(event) =>
                        handleDeleteIconClick(event, industryName)
                      }
                      className={classes.iconButton} // Apply the styles
                    />
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemText primary={industryDetails} />
                    </ListItem>
                    {prospects &&
                      prospects.map((prospect, index) => (
                        <ListItem key={index} className="flex justify-between">
                          <div className="flex-1">
                            <ListItemText
                              primary={`Company: ${prospect.company_name}`}
                              secondary={`Reason: ${prospect.prospect_reason}`}
                            />
                          </div>
                          <div className="space-x-2">
                            {currentActiveChatThread.id ===
                            prospect.prospect_id ? (
                              <ChatBubble
                                fontSize="small"
                                onClick={handleCloseChat}
                                className={classes.prospectIconButton}
                              />
                            ) : (
                              <ChatBubbleOutline
                                fontSize="small"
                                onClick={(event) =>
                                  handleChatIconClick(
                                    userId,
                                    caseId,
                                    event,
                                    prospect.prospect_id,
                                    prospect.company_name,
                                    'prospect',
                                    segmentId,
                                    prospect.prospect_id,
                                    prospect.company_domain
                                  )
                                }
                                className={classes.prospectIconButton}
                                style={{ zIndex: 9999 }}
                              />
                            )}
                            <Delete
                              fontSize="small"
                              onClick={(event) =>
                                handleDeleteIconClick(event, prospectIds[index])
                              }
                              className={classes.prospectIconButton}
                            />
                          </div>
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            );
          }
          return null; // Exclude rendering for empty industries
        })}
      </div>
      {Object.keys(currentActiveChatThread).length > 0 && (
        <div
          className={`bg-gray-300 h-full p-4 ml-2 top-10 ${
            currentActiveChatThread
              ? isChatExpanded
                ? 'w-full' // If chat is expanded, take full width
                : 'w-1/3' // If chat is not expanded, take 1/3 with
              : 'hidden'
          }`}
        >
          {/* Add a collapse/expand button */}
          <div className="flex justify-between px-2 items-center">
            {isChatExpanded ? (
              <FiArrowRightCircle
                className={classes.collapseExpandButton}
                onClick={handleToggleChat}
              />
            ) : (
              <FiArrowLeftCircle
                className={classes.collapseExpandButton}
                onClick={handleToggleChat}
              />
            )}
            <button
              className="rounded-full bg-gray-200 hover:bg-gray-300 transition-colors cursor-pointer"
              onClick={handleCloseChat}
            >
              <Close />
            </button>
          </div>
          <div>
            <ChatDialog
              userId={currentActiveChatThread.userId}
              caseId={currentActiveChatThread.caseId}
              threadId={currentActiveChatThread.id} // this is the same as segmentId and industries[segmentId] has user and caseId
              title={currentActiveChatThread.title}
              target={currentActiveChatThread.target}
              segmentId={currentActiveChatThread.segmentId} // Pass segmentId as a prop
              prospectId={currentActiveChatThread.prospectId} // Pass prospectId as a prop
              // onCloseChat={handleCloseChat}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default IndustryComponent;
