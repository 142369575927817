import React, {useEffect, useState} from 'react';
import { Widget, addResponseMessage } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';
import logo from './sales.jpeg';
import axios from 'axios';
import config from "../../../Constant"
import "./styles.css"

function getResponse(inputText) {
  const requestData = {
    input_text: inputText
  };

  return axios.post(config.CHAT.URI + "/get_response_leadpilot", requestData)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return null;
    });
}

function App() {

    const [conv, setConv] = useState("")

    useEffect(() => {
        addResponseMessage('How can I help');
      }, []);

    const handleNewUserMessage = async (newMessage) => {
        // console.log(`New message incoming! ${newMessage}`);
        let tmp = conv + `${newMessage} \n`
        const { response }  = await getResponse(tmp)
        tmp = tmp + `${response} \n`
        addResponseMessage(response)
        setConv(tmp)
        // Now send the message throught the backend API
        };

    return (

        <Widget 
            handleNewUserMessage={handleNewUserMessage}
            profileAvatar={logo}
            launcherOpenImg={logo}
            title="Ask Any Question"
            subtitle="Virtual Sales Team"
            emojis={true}
            resizable={true}
        />

  );
}

export default App;