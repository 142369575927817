import {useNavigate} from 'react-router-dom';

const PrivacyPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:m-20 m-4 justify-center ">
      <div className="mb-10">
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto"
          onClick={()=>navigate(-1)}
        > go back </button>
      </div>
      <strong>Privacy Policy</strong>
      <p> LeadPilot.Cloud built the LeadPilot.Cloud site. This SERVICE is provided by LeadPilot.Cloud and is intended for use as is. </p>
      <p>
                This page is used to inform visitors regarding our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service.
      </p>
      <p>
                If you choose to use our Service, then you agree to the collection and use of information in relation to this policy.
                The Personal Information that we collect is used for providing and improving the Service.
                We will not use or share your information with anyone except as described in this Privacy Policy.
      </p>
      <p>
                We <strong> DO NOT COLLECT, DISCLOSE OR SHARE </strong> any of your Personal Information with other party.
                Our Service is aimed to provide the maximum protection to your privacy.
      </p>
      <p>
                The terms used in this Privacy Policy have the same meanings
                as in our Terms and Conditions, which is accessible at
                LeadPilot.Cloud unless otherwise defined in this Privacy Policy.
      </p>
      <p> <strong>Information Collection and Use</strong></p>
      <p>
                To protect your privacy, the real-time video monitoring is secured and protected by <strong> TLS (Transport Layer Security) </strong> and <strong> peer-to-peer </strong> technology.
                The video is directly transfered through TLS from the camera to your viewing device.
                <ul>
                  <li><a href="https://en.wikipedia.org/wiki/Transport_Layer_Security" target="_blank" rel="noopener noreferrer">TLS (Transport Layer Security)</a>
                  </li>
                  <li><a href="https://webrtc-security.github.io/" target="_blank" rel="noopener noreferrer">WebRTC Peer-2-Peer Video Technology</a>
                  </li>
                </ul>
      </p>
      <p>
                If you decide to use our web storage, our web storage is secured and protected by our software and infrasture of <strong> AWS(Amazon Web Services) S3(Simple Storage Service) </strong>.
                <ul>
                  <li><a href="https://aws.amazon.com/security/" target="_blank" rel="noopener noreferrer">AWS Cloud Security</a>
                  </li>
                  <li><a href="https://aws.amazon.com/s3/security/" target="_blank" rel="noopener noreferrer">AWS S3 Storage</a>
                  </li>
                </ul>
      </p>
      <p><strong>Log Data</strong></p> <p>
                Currently we do not use any log data on your device.
                We will inform you in the future if we decide to use log data on your device for debugging purpose.

      </p>
      <p><strong>Cookies</strong></p> <p>
                Cookies are files with a small amount of data that are
                commonly used as anonymous unique identifiers. These are sent
                to your browser from the websites that you visit and are
                stored on your device's internal memory.
                </p> <p>
                This Service does not use these “cookies” explicitly. However,
                the app may use third party code and libraries that use
                “cookies” to collect information and improve their services.
                You have the option to either accept or refuse these cookies
                and know when a cookie is being sent to your device. If you
                choose to refuse our cookies, you may not be able to use some
                portions of this Service.
      </p>
     <p><strong>Service Providers</strong></p> <p>
              We may employ third-party companies and
              individuals due to the following reasons:
            </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
              We want to inform users of this Service
              that these third parties have access to your Personal
              Information. The reason is to perform the tasks assigned to
              them on our behalf. However, they are obligated not to
              disclose or use the information for any other purpose.
     </p>
     <p><strong>Security</strong></p> <p>
              We value your trust in providing us your
              Personal Information, thus we are striving to use commercially
              acceptable means of protecting it. But remember that no method
              of transmission over the internet, or method of electronic
              storage is 100% secure and reliable, and we cannot
              guarantee its absolute security.
     </p>
     <p><strong>Links to Other Sites</strong></p> <p>
              This Service may contain links to other sites. If you click on
              a third-party link, you will be directed to that site. Note
              that these external sites are not operated by us.
              Therefore, we strongly advise you to review the
              Privacy Policy of these websites. We have
              no control over and assume no responsibility for the content,
              privacy policies, or practices of any third-party sites or
              services.
     </p>
     <p><strong>Children’s Privacy</strong></p> <p>
              These Services do not address anyone under the age of 13.
              We do not knowingly collect personally
              identifiable information from children under 13. In the case
              we discover that a child under 13 has provided
              us with personal information, we immediately
              delete this from our servers. If you are a parent or guardian
              and you are aware that your child has provided us with
              personal information, please contact us so that
              we will be able to do necessary actions.
      </p>
      <p><strong>Changes to This Privacy Policy</strong></p> <p>
              We may update our Privacy Policy from
              time to time. Thus, you are advised to review this page
              periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page.
            </p> <p>This policy is effective as of 2020-11-20</p> 
      <p><strong>Contact Us</strong></p> <p>
              If you have any questions or suggestions about our
              Privacy Policy, do not hesitate to contact us at admin@LeadPilot.Cloud. </p>
    </div>
  )
}

export default PrivacyPage
