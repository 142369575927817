import { createSlice } from '@reduxjs/toolkit';

const industrySlice = createSlice({
  name: 'industry',
  initialState: {
    industries: {},
  },
  reducers: {
    setIndustries: (state, action) => {
      state.industries = action.payload;
    },
    addProspectToIndustry: (state, action) => {
      const { segmentId, newProspectData, newProspectIds } = action.payload;

      // Find the industry by segmentId
      const industry = state.industries[segmentId];

      if (industry) {
        // Add the new prospect to the prospects array
        industry.prospects = [...industry.prospects, ...newProspectData];
        // Add the prospect ID to the prospectIds array
        if (industry.prospectIds == '[]') {
          industry.prospectIds = newProspectIds;
        } else {
          industry.prospectIds = [...industry.prospectIds, ...newProspectIds];
        }
      }
    },
  },
});

export const { setIndustries, addProspectToIndustry } = industrySlice.actions;

export default industrySlice.reducer;
