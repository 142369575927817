import WorkSideBar from './WorkSideBar';
import React, { useState, useEffect } from 'react';
import CaseForm from './Case/CaseForm';
import ExistingCaseList from './Case/ExistingCaseList';
import { useAuth0 } from '@auth0/auth0-react';
import ProspectList from './Prospect/ProspectList';
import { useSelector, useDispatch } from 'react-redux';
import { setMainContent } from '../../redux/workAreaSlice';
import Cart from './Cart/Cart';

const WorkArea = () => {
  const mainContent = useSelector((state) => state.workArea.mainContent);
  const activeCaseItem = useSelector((state) => state.workArea.activeCaseItem);

  const dispatch = useDispatch();

  // Use local storage to store and retrieve user information
  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    return storedUserInfo || {};
  });

  const { isAuthenticated, user } = useAuth0();

  const handleMenu = (menuName) => {
    dispatch(setMainContent(menuName));
    localStorage.setItem('mainContent', menuName); // Save to localStorage
  };

  // Save user information to local storage when it changes
  useEffect(() => {
    if (user) {
      const userInfoToStore = { email: user.email };
      setUserInfo(userInfoToStore);
      localStorage.setItem('userInfo', JSON.stringify(userInfoToStore));
    }
  }, [user]);

  console.log('workArea user from auth0 is', user);

  return (
    <div className="h-screen flex">
      <WorkSideBar handleMenuClick={handleMenu} />

      <main className="flex-grow overflow-y-auto">
        <div className="flex flex-col h-screen">
          {mainContent === 'CreateCase' && <CaseForm />}
          {mainContent === 'QueryCase' && (
            <ExistingCaseList userId={userInfo.email} />
          )}
          {mainContent === 'ShowCart' &&
            (activeCaseItem.caseId ? (
              <Cart userId={userInfo.email} caseId={activeCaseItem.caseId} />
            ) : (
              <div> Please select a case to view </div>
            ))}
          {mainContent === 'ShowCaseList' &&
            (isAuthenticated ? (
              <ProspectList userId={userInfo.email} />
            ) : (
              <div> Please login to </div>
            ))}
        </div>
      </main>
    </div>
  );
};

export default WorkArea;
