import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function LoadingSpinner() {
  return (
    <div className="flex items-center justify-center">
      <FontAwesomeIcon icon={faSpinner} spin className="mr-3 text-blue-500" />
      <span>Loading...</span>
    </div>
  );
}

export default LoadingSpinner;
