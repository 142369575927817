import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import config from '../../../Constant';
import { Refresh } from '@mui/icons-material';

const GenerateIndustryButton = ({ userId, caseId, segmentId, buttonText }) => {
  const [status, setStatus] = useState('initial');
  const handleGenerate = async () => {
    try {
      await axios.post(config.INDUSTRY.BUILD_MORE_INDUSTRY_URI, {
        userId,
        caseId,
        segmentId,
      });
      setStatus('submitted'); // Call the callback function to update the parent component
    } catch (error) {
      console.error('Error generating prospect list:', error);
    }
  };

  return status === 'submitted' ? (
    <div className="text-blue-500"> Processing ... </div>
  ) : (
    <button
      className="bg-blue-500 text-white py-2 px-4 mr-4 rounded z-9999"
      onClick={(evt) => {
        evt.preventDefault();
        console.log('GenerateIndustryButton is clicked');
        handleGenerate();
      }}
    >
      <Refresh />
    </button>
  );
};

export default GenerateIndustryButton;
