import React, { useState, useEffect } from 'react';
import { MessageList, Input } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import axios from 'axios';
import config from '../../../Constant';
import formatServerCmdResponse from './ChatUtils';
import ReactHtmlParser from 'react-html-parser';

const ChatDialog = (props) => {
  const {
    threadId,
    title,
    userId,
    caseId,
    segmentId,
    prospectId,
    companyDomain,
  } = props;
  const [history, setHistory] = useState([]);

  // Define an initial message for the chat thread
  const initialMessage = {
    sender: 'host',
    type: 'text',
    text: (
      <div>
        How may I help you? You can perform the followings:
        <br />
        <br />
        1. To find potential contacts, type <em>'/a c'</em>
        <br />
      </div>
    ),
  };

  // Function to add a message to the chat history
  const addMessageToHistory = (message) => {
    setHistory((prevHistory) => [...prevHistory, message]);
  };

  const handleSendMessage = async (threadId, segmentId, prospectId, text) => {
    if (text.trim() === '') return;

    const message = {
      sender: 'user',
      text: text,
      type: 'text',
    };
    addMessageToHistory(message);

    const data = {
      prospect_name: title,
      question: text,
      case_id: caseId,
      prospect_id: prospectId,
      segment_id: segmentId,
      user_id: userId,
      company_domain: companyDomain,
    };

    // Send the user message to the server
    const response = await axios.post(`${config.PROSPECT_CHAT.ADD_MESSAGE}`, {
      data,
    });
    console.log('response', response);

    // Add the server response to the local history
    const parsedResponse = response.data.response.replace(/\n/g, '<br>'); // Replace newlines with <br> tags

    if (response.data && response.data.response) {
      const serverResponse = {
        sender: 'host',
        text: ReactHtmlParser(parsedResponse),
        type: 'text',
      };
      addMessageToHistory(serverResponse);
    }

    if (response.data && response.data.cmd_response) {
      const cmd_resp_json = JSON.parse(response.data.cmd_response);
      const serverResponse = formatServerCmdResponse(
        cmd_resp_json,
        userId,
        caseId,
        prospectId,
        new Date() // this is a new message anyway
      );
      addMessageToHistory(serverResponse);
    }
  };

  // Function to fetch and load chat history
  const loadChatHistory = async () => {
    try {
      // Replace 'your_api_endpoint' with the actual endpoint to retrieve chat history
      const response = await axios.get(
        `${config.PROSPECT_CHAT.LOAD_HISTORY}?user_id=${userId}&prospect_id=${prospectId}`
      );
      const chatHistory = response.data.chat_history || [];
      /*
          chatHistory = [{
                "userId": message["userId"]["S"],
                "prospectId": message["prospectId"]["S"],
                "messageId": message["messageId"]["S"],
                "sender": message["sender"]["S"],
                "text": message["message_text"]["S"],
                "timestamp": message["timestamp"]["S"],
                "type": message["message_type"]["S"],
                "cmd": message.get("message_cmd", {}).get("S", ""), -> cmd_response object below (json string)
            }]

          cmd_response = {
            "userId": user_id,
            "prospectId": prospect_id,
            "sender": "host",
            "cmd": json.dumps(response), this field has {cmd, contacts, job_titles, company_found, company_name} if cmd = "/a c"
            "type": "text",
      */

      // Convert chat history to the required format (MessageList items)
      const formattedChatHistory = chatHistory.map((message) => {
        if (message.cmd) {
          // Check if the message is a server command response
          const formattedResponse = formatServerCmdResponse(
            JSON.parse(message.cmd),
            userId,
            caseId,
            prospectId,
            new Date(+message.timestamp * 1000)
          );
          return formattedResponse;
        } else {
          // Regular user or host message
          return {
            position: message.sender === 'user' ? 'right' : 'left',
            type: message.type,
            text: message.text,
            sender: message.sender,
            date: new Date(+message.timestamp * 1000), // Convert s to ms and then date
          };
        }
      });

      // Sort the chat history by date
      formattedChatHistory.sort((a, b) => a.date - b.date);

      // Set the chat history in the component's state
      setHistory(formattedChatHistory);
    } catch (error) {
      console.error('Error loading chat history:', error);
    }
  };

  // Load chat history when the component mounts (you can trigger this at the appropriate time)
  useEffect(() => {
    loadChatHistory();
  }, [prospectId]);

  return (
    <div className="relative chat-dialog m-2">
      <div className="flex items-center justify-center mb-5">
        <h2 className="text-xl font-semibold text-gray-600">{title}</h2>
      </div>
      {/* Wrap the MessageList and Input in a scrollable container */}
      <div
        className="chat-content overflow-y-auto"
        style={{ maxHeight: '1000px' }}
      >
        {/* Display the initial message here */}
        <MessageList
          className="message-list"
          lockable={true}
          toBottomHeight={'100%'}
          dataSource={[
            {
              position: 'left',
              type: 'text',
              text: initialMessage.text,
              date: new Date(),
            },
            ...history.map((message) => ({
              position: message.sender === 'user' ? 'right' : 'left',
              type: message.type,
              text: message.text,
              date: message.date,
            })),
          ]}
        />

        <Input
          className="bg-white border rounded mt-4 w-full"
          placeholder="Type here..."
          defaultValue=""
          multiline={true}
          autofocus={true}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSendMessage(
                threadId,
                segmentId,
                prospectId,
                event.target.value
              );
              event.target.value = '';
            }
          }}
        />
      </div>
    </div>
  );
};

export default ChatDialog;
