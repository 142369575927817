const prod = {
  AUTH0: {
    // Auth0
    DOMAIN: 'leadpilot-cloud.us.auth0.com',
    CILENTID: 't2CChVB2cr4iw57iEaFjCmewP9Bixc0H',
    REDIRECTURI: 'https://leadpilot.cloud/app',
    // SIP SERVER
    // AUDIENCE_APP: "https://sip.vona.ai/",
    AUDIENCE_APP: 'https://leadpilot-cloud.us.auth0.com/api/v2/',
    // STRIPE
    // After creating the API, those will be generated
    JWK_SECRETE_URI:
      'https://leadpilot-cloud.us.auth0.com/.well-known/jwks.json',
    JWK_ISSUER: 'https://leadpilot-cloud.us.auth0.com/',
    JWK_AUDIENCE_STRIPE: 'https://sip.vona.ai/pay/',
  },
  STRIPE: {
    CREATE_SESSION_ENDPOINT:
      'https://sip.vona.ai/pay_pet/create-checkout-session',
    CHECK_SUBSCRIPTION_ENDPOINT: 'https://sip.vona.ai/pay_pet/get_subscription',
    PORTAL_ENDPOINT: 'https://sip.vona.ai/pay_pet/customer-portal',
    PRICE_ID_STANDARD: 'price_1MdRUtIx0wiOxwYB3nbw2TUI',
    PRICE_ID_PRO: 'price_1MdRUtIx0wiOxwYB3nbw2TUI',
  },
  SIP: {
    WS_SERVER_URI: 'wss://sip.vona.ai/',
    DEBUG_ROOM_ENDPOINT: 'https://sip.vona.ai/rooms/v1',
  },
  EMAIL: {
    CONTACT_ENDPOINT: 'https://sip.vona.ai/contact',
  },
  CHAT: {
    URI: 'https://sip.vona.ai/bot',
  },
  CASE: {
    CREATE_CASE_URI:
      'https://hxjfpzkaxl.execute-api.us-west-2.amazonaws.com/dev/cases',
    GET_CASE_URI:
      'https://hxjfpzkaxl.execute-api.us-west-2.amazonaws.com/dev/cases',
    SCAN_CASE_URI: 'https://sip.vona.ai/bot/case/scan',
    CONSTRUCT_EMAIL_URI: 'https://sip.vona.ai/bot/construct_email',
  },
  PROSPECT: {
    GET_PROSPECT_URI: 'https://sip.vona.ai/bot/get_list',
    GET_EMAIL_URI: 'https://sip.vona.ai/bot/get_email',
    UPDATE_CART_EMAIL_URI: 'https://sip.vona.ai/bot/update_cart_email',
    BUILD_PROSPECT_URI: 'https://sip.vona.ai/bot/build_list',
    BUILD_PROSPECT_BY_SEGMENT_URI:
      'https://sip.vona.ai/bot/build_list_by_segment',
    DELETE_PROSPECT_URI: 'https://sip.vona.ai/bot/delete_prospect',

    LOAD_CART_URI: 'https://sip.vona.ai/bot/load-cart',
    SAVE_CART_ITEM_URI: 'https://sip.vona.ai/bot/save-cart-item',
  },
  PROSPECT_CHAT: {
    ADD_MESSAGE: 'https://sip.vona.ai/bot/prospect_chatbot',
    LOAD_HISTORY: 'https://sip.vona.ai/bot/prospect_chat_history',
  },
  INDUSTRY: {
    GET_INDUSTRY_URI: 'https://sip.vona.ai/bot/get_industry',
    BUILD_MORE_INDUSTRY_URI: 'https://sip.vona.ai/bot/build_more_industry',
  },
};

const dev = {
  AUTH0: {
    // Auth0
    DOMAIN: 'dev-abl1i6ft.us.auth0.com',
    CILENTID: 'giq4yxq03Et2lL0b41ZFlhwNBz3JPy2Q',
    REDIRECTURI: 'http://localhost:3000/app',
    AUDIENCE_APP: 'https://dev-abl1i6ft.us.auth0.com/api/v2/',
    JWK_SECRETE_URI: 'https://dev-abl1i6ft.us.auth0.com/.well-known/jwks.json',
    JWK_ISSUER: 'https://dev-abl1i6ft.us.auth0.com/',
    JWK_AUDIENCE_STRIPE: 'http://localhost:4242',
  },
  STRIPE: {
    CREATE_SESSION_ENDPOINT: 'http://localhost:4242/create-checkout-session',
    CHECK_SUBSCRIPTION_ENDPOINT: 'http://localhost:4242/get_subscription',
    PORTAL_ENDPOINT: 'http://localhost:4242/customer-portal',
    PRICE_ID_STANDARD: 'price_1MdSBQIx0wiOxwYBo6MyAQKH',
    PRICE_ID_PRO: 'price_1MdSBQIx0wiOxwYBo6MyAQKH',
  },
  SIP: {
    WS_SERVER_URI: 'ws://localhost:7000',
  },
  EMAIL: {
    CONTACT_ENDPOINT: 'http://localhost:7000/contact',
  },
  CHAT: {
    URI: 'http://localhost:6001',
  },
  CASE: {
    CREATE_CASE_URI:
      'https://hxjfpzkaxl.execute-api.us-west-2.amazonaws.com/dev/cases',
    GET_CASE_URI:
      'https://hxjfpzkaxl.execute-api.us-west-2.amazonaws.com/dev/cases',
    SCAN_CASE_URI: 'http://localhost:6001/case/scan',
    CONSTRUCT_EMAIL_URI: 'http://localhost:6001/construct_email',
  },
  PROSPECT: {
    GET_PROSPECT_URI: 'http://localhost:6001/get_list',
    GET_EMAIL_URI: 'http://localhost:6001/get_email',
    UPDATE_CART_EMAIL_URI: 'http://localhost:6001/update_cart_email',
    BUILD_PROSPECT_URI: 'http://localhost:6001/build_list',
    BUILD_PROSPECT_BY_SEGMENT_URI:
      'http://localhost:6001/build_list_by_segment',
    DELETE_PROSPECT_URI: 'http://localhost:6001/delete_prospect',

    LOAD_CART_URI: 'http://localhost:6001/load-cart',
    SAVE_CART_ITEM_URI: 'http://localhost:6001/save-cart-item',
  },
  PROSPECT_CHAT: {
    ADD_MESSAGE: 'http://localhost:6001/prospect_chatbot',
    LOAD_HISTORY: 'http://localhost:6001/prospect_chat_history',
  },
  INDUSTRY: {
    GET_INDUSTRY_URI: 'http://localhost:6001/get_industry',
    BUILD_MORE_INDUSTRY_URI: 'http://localhost:6001/build_more_industry',
  },
};

const config = process.env.REACT_APP_ENV === 'prd' ? prod : dev;

export default config;
