import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../../Constant';
import IndustryListContent from './IndustryListContent';
import SelectCase from './SelectCase';
import { useSelector } from 'react-redux';

const ProspectList = ({ userId }) => {
  const [cases, setCases] = useState([]);
  const caseItem = useSelector((state) => state.workArea.activeCaseItem);

  // fetch the cases and display them for user to select a case first
  useEffect(() => {
    const fetchCases = async () => {
      const res = await axios.get(
        config.CASE.GET_CASE_URI + `?userId=${userId}`
      );
      setCases(
        res.data?.cases.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    };

    fetchCases();
  }, [userId]);

  return caseItem?.caseId ? (
    <IndustryListContent
      userId={userId}
      caseId={caseItem.caseId}
      caseName={caseItem.caseName}
    />
  ) : (
    <SelectCase cases={cases} />
  );
};

export default ProspectList;
