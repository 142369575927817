import React from 'react'
import WorkArea from '../components/compain-page/WorkArea'
import { ProSidebarProvider } from 'react-pro-sidebar';

const Page = () => {
  return ( 
         <ProSidebarProvider>
            <WorkArea />
         </ProSidebarProvider>
         )
}

export default Page