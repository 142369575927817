// https://github.com/simple-icons/simple-icons/blob/master/slugs.md
import { useTranslation } from 'react-i18next';

const CompanyLogos = () => {
  const { t } = useTranslation();

  return (
    <section className="container mt-28 text-center">
      <h3 className="text-[32px] font-bold mb-8">{t('Customers')}</h3>
      <div className="flex flex-wrap justify-center items-center mb-20 gap-40">
        {/* <img
          className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 object-contain"
          alt="logo"
          src="https://unpkg.com/simple-icons@v8/icons/avast.svg"
          color="#F46737"
        />
        <img
          className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 object-contain"
          alt="logo"
          src="https://unpkg.com/simple-icons@v8/icons/abletonlive.svg"
          color="#FF9A00"
        />
        <img
          className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 object-contain"
          alt="logo"
          src="https://unpkg.com/simple-icons@v8/icons/beatsbydre.svg"
        />
        <img
          className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 object-contain"
          alt="logo"
          src="https://unpkg.com/simple-icons@v8/icons/behance.svg"
        /> */}
        {/* <img
          className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 object-contain"
          alt="logo"
          src="https://unpkg.com/simple-icons@v8/icons/evernote.svg"
        /> */}
        <img
          className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 object-contain"
          alt="logo"
          src="https://www.changhong.us/wp-content/uploads/2023/06/cropped-cropped-logo-1.png"
        />
        <img
          className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 object-contain"
          alt="logo"
          src="https://www.shiweixr.com/images/img/logo.png"
        />
        {/* <img
          className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 object-contain"
          alt="logo"
          src="https://unpkg.com/simple-icons@v8/icons/wipro.svg"
        /> */}
      </div>
    </section>
  );
};

export default CompanyLogos;
