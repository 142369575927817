import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const handleLogout = () => {
    // Clear the stored state and log out the user
    localStorage.removeItem('mainContent');
    logout({ returnTo: window.location.origin });
  };

  return <button onClick={handleLogout}>Log Out</button>;
};

export default LogoutButton;
