import React from 'react';
import '../../index.css';
import LoginButton from '../auth0/LoginButton';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
  // const navItems = [];
  const {
    handlePriceClick,
    handleProductClick,
    handleCustClick,
    handleContactClick,
    hideSignIn,
  } = props;

  const { t } = useTranslation();
  return (
    <header className=" container flex justify-space h-20 mb-4">
      <div className=" container flex pl-0 justify-start items-center h-20">
        <p className="lg:inline-flex lg:text-3xl text-2xl font-bold text-primary">
          LeadPilot.Cloud
        </p>
      </div>
      <div className="flex items-center">
        <div className="md:flex items-center space-x-3 lg:space-x-8">
          <p
            className="w-40 hidden md:flex nav-item"
            onClick={handleProductClick}
          >
            {t('Product')}
          </p>
          <p
            className="w-40 hidden md:flex nav-item"
            onClick={handlePriceClick}
          >
            {t('Price')}
          </p>
          <p className="w-40 hidden md:flex nav-item" onClick={handleCustClick}>
            {t('Customer')}
          </p>
          <p
            className="w-40 hidden md:flex nav-item"
            onClick={handleContactClick}
          >
            {t('ContactUs')}
          </p>
          {hideSignIn !== true && (
            <div className="secondary-button md:primary-button">
              <LoginButton text={t('Sign In')} />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
