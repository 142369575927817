// workAreaSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainContent: localStorage.getItem('mainContent') || 'CreateCase',
};

const workAreaSlice = createSlice({
  name: 'workArea',
  initialState,
  reducers: {
    setMainContent: (state, action) => {
      state.mainContent = action.payload;
    },
    setActiveCase: (state, action) => {
      state.activeCaseItem = action.payload;
    },
  },
});

export const { setMainContent, setActiveCase } = workAreaSlice.actions;
export default workAreaSlice.reducer;
