import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton'; // Import IconButton from Material-UI
import DeleteIcon from '@mui/icons-material/Delete'; // Import DeleteIcon from Material-UI
import Button from '@mui/material/Button'; // Import Button from Material-UI
import Grid from '@mui/material/Grid'; // Import Grid from Material-UI
import Avatar from '@mui/material/Avatar'; // Import Avatar from Material-UI
import Typography from '@mui/material/Typography'; // Import Typography from Material-UI
import axios from 'axios'; // Import Axios for making the POST request to initiate the email
import config from '../../../Constant'; // Import your configuration file
import { useSelector } from 'react-redux'; // Import useSelector from react-redux
import { useDispatch } from 'react-redux';
import { addToCart, updateCartItemEmail } from '../../../redux/cartSlice'; // Import the addToCart action

const CartCard = ({
  person,
  title,
  // userId,
  // caseId,
  segmentId,
  prospectId,
  onRemove,
  email_content,
  email_subject,
}) => {
  const dispatch = useDispatch();

  const [showEmailContent, setShowEmailContent] = useState(false);
  const [isDraftingEmail, setIsDraftingEmail] = useState(false);
  const [draftEmailContent, setDraftEmailContent] = useState('');
  const activeCaseItem = useSelector((state) => state.workArea.activeCaseItem); // Get data from Redux store
  const caseId = activeCaseItem?.caseId; // Get caseId from Redux store
  const userId = activeCaseItem.userId; // Get userId from Redux store

  const handleDraftEmail = async () => {
    setIsDraftingEmail(true);

    // update cart email
    // Define the data to send in the POST request (adjust the data structure as needed)
    const emailData = {
      action: 'new', // Or another appropriate action value
      caseId: caseId, // You may need to pass caseId as a prop or from your component's state
      userId: userId, // You may need to pass userId as a prop or from your component's state
      customerInfoUrl:
        person.organization.website_url || person.organization.linkedin_url, // Replace with the actual URL
      name: `${person.first_name} ${person.last_name}`,
      receipient_name: person.name,
      receipient_email: person.email,
      receipient_title: person.title,
      receipient_company: person.organization.name,
      sender_name: userId,
      sender_email: userId,
      sender_title: '',
      sender_company: '',
      prospectId,
      // [TODO]: we need a name card for everyone who is using the system
      // email: person.email,
      // Add other relevant data for email construction
    };

    try {
      // Make a POST request to your server endpoint to initiate the email
      const response = await axios.post(
        `${config.CASE.CONSTRUCT_EMAIL_URI}`,
        emailData
      );
      // Handle the response, e.g., show a success message
      console.log('Email initiated:', response.data);

      // save cart item
      // Create an object with email as key and person as value
      const cartEmailItem = {
        prospectId,
        emailContent: response.data.email_content,
        emailSubject: response.data.email_subject,
        personEmail: person.email,
      };

      // Save the email content and subject to the cart item
      const cartEmail = {
        userId: userId,
        caseId: caseId,
        prospectId: prospectId,
        personEmail: person.email,
        emailContent: response.data.email_content,
        emailSubject: response.data.email_subject,
      };

      // Update the cart item's email content and subject using the backend endpoint
      try {
        await axios.put(`${config.PROSPECT.UPDATE_CART_EMAIL_URI}`, cartEmail);
        console.log('Cart item email updated successfully.');
      } catch (error) {
        console.error('Error updating cart item email:', error);
        // Handle error as needed
      }

      // update UO
      dispatch(updateCartItemEmail(cartEmailItem));

      // update UI
      setShowEmailContent(true);
      // Set the draft email content and subject
      setDraftEmailContent(response.data.email_content);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Error initiating email:', error);
    } finally {
      setIsDraftingEmail(false);
    }
  };

  return (
    <div className={`cart-item ${showEmailContent ? 'expanded' : ''}`}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="cart-item"
      >
        <Grid item xs={2}>
          <Avatar alt="Profile" src={person.photo_url} />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle1">
            {`${person.first_name} ${person.last_name}`}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {person.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {person.email}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" color="textSecondary">
            {person.linkedin_url && (
              <a
                href={person.linkedin_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            )}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton aria-label="Remove from cart" onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
          {email_content || draftEmailContent ? (
            <Button
              className={`w-40 ${
                email_content ? 'bg-blue-500' : 'bg-green-500'
              }`}
              variant="outlined"
              size="small"
              onClick={() => setShowEmailContent(!showEmailContent)}
            >
              {showEmailContent ? 'Hide Email' : 'Review Email'}
            </Button>
          ) : (
            // Draft email content, show Draft Email button
            <Button
              className={`w-40 ${
                isDraftingEmail ? 'bg-gray-400' : 'bg-green-500'
              }`}
              variant="outlined"
              size="small"
              onClick={handleDraftEmail}
              disabled={isDraftingEmail}
            >
              {isDraftingEmail ? 'Drafting...' : 'Draft Email'}
            </Button>
          )}
        </Grid>
      </Grid>
      {showEmailContent && (email_content || draftEmailContent) && (
        <div className="email-content">
          <Typography variant="body2" color="textPrimary">
            <strong>Email Content:</strong>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {email_content || draftEmailContent}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CartCard;
