import React from 'react';
import { useSelector } from 'react-redux';

const DownloadCSVButton = () => {
  const cartItems = useSelector((state) => state.cart.items);

  const generateCSV = () => {
    if (Object.keys(cartItems).length === 0) {
      // Handle the case where the cart is empty
      return;
    }

    // Create a CSV file
    const csvRows = [];

    // Iterate through cart items
    for (const prospectId in cartItems) {
      const company = cartItems[prospectId][0].title;

      // Iterate through each person in the company
      cartItems[prospectId].forEach(
        ({ person, email_content, email_subject }) => {
          const row = [
            company,
            person.first_name,
            person.last_name,
            person.email,
            `"${email_content}"`, // Add email_content column wrapped in double quotes
            `"${email_subject}"`, // Add email_subject column wrapped in double quotes
          ];
          csvRows.push(row.join(','));
        }
      );
    }

    // Combine rows into the final CSV content
    const csvContent = [
      'Company,First Name,Last Name,Email,Email Content,Email Subject',
      ...csvRows,
    ].join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'cart.csv';

    // Trigger the click event to download the CSV file
    a.click();

    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(a.href);
  };

  return <button onClick={generateCSV}>Download CSV</button>;
};

export default DownloadCSVButton;
