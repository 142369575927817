import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FrontPage from './pages/FrontPage';
import FrontPageV1 from './pages/FrontPageV1';
import ContactPage from './pages/ContactPage';
import PrivacyPage from './pages/legals/PrivacyPage';
import TermPage from './pages/legals/TermPage';
import SubscriptionPage from './pages/SubscriptionPage';
import Compain from './pages/Compain';
import './locales/i18n';

function App() {
  return (
    <Suspense
      fallback={
        <div className="flex h-screen justify-center items-center">
          <div
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#333',
            }}
          >
            Loading...
          </div>
        </div>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FrontPage />}></Route>
          <Route path="/app" element={<Compain />}></Route>
          <Route path="/subscription" element={<SubscriptionPage />}></Route>
          <Route path="/contactUs" element={<ContactPage />}></Route>
          <Route path="/privacy" element={<PrivacyPage />}></Route>
          <Route path="/term" element={<TermPage />}></Route>
          <Route path="/ch-poc/" element={<FrontPageV1 />}></Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
