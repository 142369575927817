import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'; // import video.js styles
import Hls from 'hls.js';

const PlayVideo = (props) => {
  const videoRef = useRef(null);
  const { videoSource } = props;

  useEffect(() => {
    let hls = null;

    // Check if HLS.js is supported
    if (Hls.isSupported()) {
      hls = new Hls();
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      // For Safari, use native HLS support
      videoRef.current.src = videoSource;
      return;
    } else {
      // HLS.js is not supported and neither is native HLS support
      // Handle the lack of support, show an error message, etc.
      return;
    }

    // Initialize video.js player
    const player = videojs(videoRef.current, {
      fluid: true,
      autoplay: true,
      controls: true,
    });

    // Attach HLS.js to the video element
    hls.loadSource(videoSource);
    hls.attachMedia(videoRef.current);

    // Clean up HLS.js and video.js player on component unmount
    return () => {
      if (hls) {
        hls.destroy();
      }
      if (player) {
        player.dispose();
      }
    };
  }, [videoSource]); // Re-initialize player if videoSource changes

  return (
    <div className="video-js-container">
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        playsInline
        muted
        autoPlay
        controls
      />
    </div>
  );
};

export default PlayVideo;
