import React from 'react';

import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  menuClasses,
} from 'react-pro-sidebar';
import { MdEditDocument } from 'react-icons/md';
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';
import { SiMinutemailer } from 'react-icons/si';
import { FcIdea } from 'react-icons/fc';
import LogoutButton from '../auth0/LogoutButton';

function WorkSideBar(props) {
  const { handleMenuClick } = props;
  const { collapseSidebar, collapsed } = useProSidebar();

  return (
    <Sidebar className="h-min-screen">
      {/* header portion */}
      <div className="relative">
        <div className="logotext flex flex-col items-center mb-10">
          {collapsed || (
            <div className="flex flex-col items-center mb-5">
              <img
                className="max-w-full h-auto"
                src="./images/front.png"
                alt="logo"
              />
              <div className="w-full flex items-center justify-center">
                <p className="lg:inline-flex lg:text-2xl text-xl font-bold text-primary">
                  LeadPilot.Cloud
                </p>
              </div>
            </div>
          )}
          <button
            className="rounded-full font-bold text-2xl leading-12 cursor-pointer"
            onClick={() => collapseSidebar()}
          >
            <div className="closemenu">
              {collapsed ? (
                <FiArrowRightCircle className="text-primary mt-10" />
              ) : (
                <FiArrowLeftCircle className="text-primary" />
              )}
            </div>
          </button>
        </div>
      </div>

      <Menu
        rootStyles={{
          [`.${menuClasses.icon}`]: {
            backgroundColor: '#e1e1e1',
            color: '#344cff',
          },
        }}
      >
        <MenuItem
          onClick={() => handleMenuClick('CreateCase')}
          icon={<MdEditDocument />}
        >
          Create Case
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuClick('QueryCase')}
          icon={<FcIdea />}
        >
          Cases
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuClick('ShowCaseList')}
          icon={<SiMinutemailer />}
        >
          Prospects
        </MenuItem>
      </Menu>
      <div className="absolute bottom-10 w-30 left-1/2 transform -translate-x-1/2">
        <div className="secondary-button">
          <LogoutButton text="Logout" />
        </div>
      </div>
    </Sidebar>
  );
}

export default WorkSideBar;
