import PersonCard from './PersonCard';

// Define a utility function to format server responses based on commands
/*
responseJson = 
  {
            "cmd": command,
            "contacts": contacts,
            "job_titles": job_titles,
            "company_found": company_found,
            "company_name": self.company_name,
        }

*/
const formatServerCmdResponse = (
  responseJson,
  userId,
  caseId,
  prospectId,
  responseTimeMs
) => {
  let formattedResponse = {
    position: 'left',
    sender: 'host',
    text: '',
    type: 'text',
    date: responseTimeMs,
  };

  const command = responseJson.cmd;

  switch (command) {
    case '/a c':
      formattedResponse.text = formatContactsResponse(
        responseJson,
        userId,
        caseId,
        prospectId
      );
      break;
    // Add more cases for other commands as needed
    default:
      formattedResponse.text = 'Unsupported command';
  }

  return formattedResponse;
};

// Define a function to format the response for the "/a c" command
const formatContactsResponse = (cmd_resp, userId, caseId, prospectId) => {
  const resp_data = cmd_resp.contacts;
  const job_titles = cmd_resp.job_titles; // Assuming job_titles is an array
  const found_company = cmd_resp.company_found;
  const company_name = cmd_resp.company_name;

  const text = (
    <div>
      <div>
        {found_company ? (
          <div>
            I recommend contacting the following job titles inside{' '}
            {company_name}:
            <ul>
              {job_titles.map((title, index) => (
                <li key={index}>{title}</li>
              ))}
            </ul>
          </div>
        ) : (
          <p>
            I could not find anything for this company. I recommend the
            following job titles from other companies:
            <ul>
              {job_titles?.map((title, index) => (
                <li key={index}>{title}</li>
              ))}
            </ul>
          </p>
        )}
      </div>
      <br />
      <div>
        {resp_data.map((person, index) => (
          <PersonCard
            key={index}
            person={person}
            title={company_name}
            userId={userId}
            caseId={caseId}
            prospectId={prospectId}
          />
        ))}
      </div>
    </div>
  );

  return text;
};

export default formatServerCmdResponse;
