import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../../Constant';

import { Add, Remove, Delete, PlayArrow } from '@material-ui/icons';
import {
  Tooltip,
  IconButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      fontSize: '1rem',
      width: '2rem',
      height: '2rem',
    },
  },
}));

const CaseList = ({ userId }) => {
  const [cases, setCases] = useState([]);
  const [showDetails, setShowDetails] = useState([]);
  const [scanPending, setScanPending] = useState({});
  const classes = useStyles();

  const handleDeleteCase = (caseId, userId) => {
    axios
      .delete(
        `${config.CASE.CREATE_CASE_URI}?caseId=${caseId}&userId=${userId}`
      )
      .then((response) => {
        console.log(response.data); // handle success
        const updatedCases = cases.filter((c) => c.caseId !== caseId);
        setCases(updatedCases);
      })
      .catch((error) => {
        console.log(error); // handle error
      });
  };

  const handleToggleDetails = (index) => {
    setShowDetails((prevShowDetails) => {
      const newShowDetails = [...prevShowDetails];
      newShowDetails[index] = !newShowDetails[index];
      return newShowDetails;
    });
  };

  const handleScanCase = (caseId, userId) => {
    console.log('scan');
    axios
      .post(
        `${config.CASE.SCAN_CASE_URI}`,
        { caseId, userId },
        { timeout: 100000 }
      )
      .then((response) => {
        // handle success response
        console.log(response.data);
      })
      .catch((error) => {
        // handle error response
        console.error(error);
      });
    console.log({ ...scanPending, [caseId]: true });
    setScanPending({ ...scanPending, [caseId]: true });
  };

  useEffect(() => {
    // Fetch the initial case data
    const fetchCases = async () => {
      const res = await axios.get(
        config.CASE.CREATE_CASE_URI + `?userId=${userId}`
      );
      // console.log(res.data)

      res.data.cases.forEach((c) => {
        // the scan pending is only true when scan button is pressed; it is only reset if the case got a new version or the page is restarted.
        if (cases[c.caseId]?.scanVersion !== c?.scanVersion) {
          scanPending[c.caseId] = false;
        }
      });
      setScanPending(scanPending);
      setCases(
        res.data?.cases.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    };
    fetchCases();

    // Fetch the updated case data every 30 seconds
    const intervalId = setInterval(() => {
      fetchCases();
    }, 30000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="pl-5 pr-5 mx-auto mt-20 w-full">
      <div className="flex justify-between">
        <div className="pl-2 text-2xl font-bold mb-4 text-gray-500">
          Case List
        </div>
        <div className="pr-2 text-2xl font-bold mb-4 text-gray-500 text-red-500 ">{`Total Cases: ${cases.length}`}</div>
      </div>
      {cases &&
        cases.map((c, idx) => (
          <div key={idx} className="border border-gray-300 rounded-md p-4 mb-4">
            <div className="flex justify-between mb-2">
              <div className="text-lg font-bold mr-4">{c?.caseName}</div>
              <div className={classes.iconsContainer}>
                <Tooltip
                  title={showDetails[idx] ? 'Hide details' : 'Show details'}
                >
                  <IconButton
                    onClick={() => handleToggleDetails(idx)}
                    className={classes.iconButton}
                  >
                    {showDetails[idx] ? <Remove /> : <Add />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Run report">
                  {scanPending[c.caseId] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <PlayArrow
                      onClick={() => handleScanCase(c.caseId, c.userId)}
                    />
                  )}
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDeleteCase(c.caseId, c.userId)}
                    className={classes.deleteButton}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div className="text-gray-600">{`Created by ${c.userId}`}</div>

            {/* scan result  */}
            {showDetails[idx] && c.report && ScannedData(c.report)}

            {showDetails[idx] && (
              <div className="mt-2">
                <div> {c.caseData.caseExpectation} </div>
                <div> {getCase(c.caseData)} </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

function DisplayTextWithLineBreaks(props) {
  const { text } = props;
  const lines = text.split('\n');

  return (
    <>
      {lines.map((line, idx) => (
        <div key={`line-${idx}`}>
          {line.split('\t').map((segment, segIdx) => (
            <React.Fragment key={`seg-${segIdx}`}>
              {segment}
              {segIdx < line.split('\t').length - 1 ? (
                <>&nbsp;&nbsp;&nbsp;&nbsp;</>
              ) : null}
            </React.Fragment>
          ))}
        </div>
      ))}
    </>
  );
}

const getCase = (caseData) => {
  const urlPairs = Object.keys(caseData)
    .filter((key) => key.startsWith('url_key_'))
    .filter((key) => caseData[key] !== '');

  const filePairs = Object.keys(caseData)
    .filter((key) => key.startsWith('file-description-'))
    .filter((key) => caseData[key] !== '');

  console.log(caseData, filePairs);
  return (
    <div>
      {Object.keys(urlPairs).length > 0 && (
        <>
          <h3>URLs:</h3>
          <ul>
            {urlPairs.map((k, idx) => (
              <li key={`${caseData[k.replace('url_key_', 'url_')]}_${idx}`}>
                {caseData[k]}: {caseData[k.replace('url_key_', 'url_')]}
              </li>
            ))}
          </ul>
        </>
      )}
      {Object.keys(filePairs).length > 0 && (
        <>
          {' '}
          <h3>Files:</h3>
          <ul>
            {filePairs.map((value, idx) => (
              <li
                key={`${JSON.stringify(
                  caseData[value.replace('file-description-', 'file-')]
                )}_${idx}`}
              >
                {caseData[value]}:{' '}
                {JSON.stringify(
                  caseData[value.replace('file-description-', 'file-')]
                )}
              </li>
            ))}
          </ul>{' '}
        </>
      )}
    </div>
  );
};

const ScannedData = (data) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      {Object.keys(data)
        .filter((key) => key !== 'context')
        .map((key) => (
          <div key={key} className="mb-2">
            <p className="font-bold">{key}:</p>
            <DisplayTextWithLineBreaks className="ml-2" text={data[key]} />
          </div>
        ))}
    </div>
  );
};

export default CaseList;
