import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import config from '../../../Constant';
import { useAuth0 } from '@auth0/auth0-react';
import TextareaAutosize from 'react-textarea-autosize';
import { Add, Delete } from '@material-ui/icons';
import { Tooltip, IconButton } from '@material-ui/core';

const CaseForm = (props) => {
  const { handleBeforeCaseSubmit, handleAfterCaseSubmit } = props;
  const { register, handleSubmit, formState } = useForm();
  const [urlFields, setUrlFields] = useState([]);
  const [fileFields, setFileFields] = useState([]);
  // const [caseExpectation, setCaseExpectation] = useState('');
  const [caseId, setCaseId] = useState();
  const { user } = useAuth0();
  const [files, setFiles] = useState({});

  useEffect(() => {
    if (user?.email) setCaseId(`${user.email}_${Date.now()}`);
  }, [user]);

  const onSubmit = (data) => {
    data.userId = user?.email;
    data.caseId = caseId;
    data.createTime = Date.now().toString();
    data.files = Object.keys(files).map((k) => ({ k: files[k].name })); // files = {index: file obj (name, ... )}

    // upload files
    Object.keys(files).map(async (key) => {
      await onUpload(key);
    });

    // upload the case data
    console.log('test:', data, user, config.CASE.CREATE_CASE_URI);
    handleBeforeCaseSubmit();
    axios
      .post(config.CASE.CREATE_CASE_URI, data)
      .then((response) => {
        handleAfterCaseSubmit();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadFile = async (presignedUrl, file) => {
    await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  };

  const onUpload = async (key) => {
    let file = files[key];
    console.log('upload');
    const response = await axios.get(`${config.CHAT.URI}/presigned-url`, {
      params: {
        key: caseId + '/' + file.name,
        type: file.type,
      },
    });
    const presignedUrl = response.data.presignedUrl;
    console.log('presignedUrl is ', presignedUrl);
    await uploadFile(presignedUrl, file);
  };

  const addUrlField = () => {
    setUrlFields([...urlFields, { url: '', description: '' }]);
  };

  const removeUrlField = (index) => {
    const fields = [...urlFields];
    fields.splice(index, 1);
    setUrlFields(fields);
  };

  const addFileField = () => {
    // const newId = fileFields[fileFields.length - 1].id + 1;
    setFileFields([...fileFields, { id: fileFields.length, file: null }]);
  };

  const removeFileField = (id) => {
    const newFileFields = fileFields.filter((field) => field.id !== id);
    setFileFields(newFileFields);
  };

  return (
    <form className="m-20 pb-20" onSubmit={handleSubmit(onSubmit)}>
      {/* form title  */}

      <div className="mb-8">
        {formState.errors && formState.errors.caseName && (
          <p className="mt-2 mb-1 text-red-500">
            {' '}
            {formState.errors.caseName.message}
          </p>
        )}
        <label
          htmlFor="caseName"
          className="block text-gray-700 font-bold mb-2"
        >
          Case Name
        </label>
        <input
          type="text"
          id="caseName"
          placeholder="Your marketing case name"
          {...register('caseName', { required: 'Please enter a case name' })}
          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 disabled:opacity-50"
        />
      </div>

      <div className="mb-12">
        {formState.errors && formState.errors.caseExpectation && (
          <p className="mt-2 mb-1 text-red-500">
            {' '}
            {formState.errors.caseExpectation.message}
          </p>
        )}
        <label
          htmlFor="caseExpectation"
          className="block text-gray-700 font-bold mb-2"
        >
          Your Expectations
        </label>
        <TextareaAutosize
          id="caseExpectation"
          placeholder="Elaborate on your campaign requirement"
          {...register('caseExpectation', {
            // required: 'Please elaborate on your campaign requirement',
          })}
          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 disabled:opacity-50"
          minRows={6}
          maxRows={12}
        />
      </div>

      {/* Plus Sign with Rectangular Text Area */}
      <label htmlFor="urls" className="block text-gray-700 font-bold mb-6">
        Related URLs
      </label>

      {/* url sections  */}
      <div className="mt-8 bg-gray-200 p-4 rounded-md mb-8">
        {urlFields.map((field, index) => (
          <div key={index} className="flex flex-wrap mb-6">
            {formState.errors && formState.errors[`url_${index}`] && (
              <p className="mt-1 mb-2 text-red-500">
                {formState.errors[`url_${index}`].message}
              </p>
            )}
            <div className="block w-full ">
              <div className="flex justify-between items-center justify-center">
                <label
                  htmlFor={`url_key_${index}`}
                  className="text-gray-500 font-bold mb-2 mr-5 w-30"
                >
                  URL
                </label>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => removeUrlField(index)}
                    // className={classes.deleteButton}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </div>
              <input
                type="text"
                id={`url_${index}`}
                {...register(`url_${index}`, {
                  // required: 'URL is required',
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    message: 'Invalid URL',
                  },
                })}
                className="block h-10 w-full mb-1 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-l-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 disabled:opacity-50"
                placeholder="Enter URL"
                value={field.url}
                onChange={(e) => {
                  const newFields = [...urlFields];
                  newFields[index].url = e.target.value;
                  setUrlFields(newFields);
                }}
              />
            </div>

            {formState.errors && formState.errors[`url_key_${index}`] && (
              <p className="mt-1 mb-2 text-red-500">
                {' '}
                {formState.errors[`url_key_${index}`].message}
              </p>
            )}
            <div className="block w-full">
              <label
                htmlFor={`url_key_${index}`}
                className="text-gray-500 font-bold mb-2 mr-5 w-30 flex-shrink-0"
              >
                Description
              </label>
              <input
                type="text"
                name={`url_key_${index}`}
                id={`url_key_${index}`}
                {...register(`url_key_${index}`, {
                  // required: 'please enter the description of this link',
                })}
                className="block h-10 w-full mb-1 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-r-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 disabled:opacity-50"
                placeholder="your website, competitor site, related industry, reviews and etc"
                value={field.description}
                onChange={(e) => {
                  const newFields = [...urlFields];
                  newFields[index].description = e.target.value;
                  setUrlFields(newFields);
                }}
              />
            </div>
          </div>
        ))}

        <div
          className="w-full h-20 bg-gray-200 rounded-md flex justify-center items-center cursor-pointer"
          onClick={addUrlField}
        >
          <div className="w-12 h-12 bg-blue-500 rounded-full flex justify-center items-center">
            <div className="text-white text-3xl flex items-center justify-center">
              <Tooltip title="Hide details">
                <IconButton>
                  <Add />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {/* file section   */}
      <label htmlFor="files" className="block text-gray-700 font-bold mb-6">
        Related Files
      </label>
      <div className="mt-8 bg-gray-200 p-4 rounded-md mb-8">
        {fileFields.map((field, index) => (
          <div key={field.id} className="flex flex-wrap mb-6">
            {formState.errors && formState.errors[`file-${index}`] && (
              <p className="mt-1 mb-2 text-red-500">
                {' '}
                {formState.errors[`file-${index}`].message}
              </p>
            )}
            <div className="block w-full ">
              <div className="flex justify-between items-center justify-center">
                <label
                  htmlFor={`url_key_${index}`}
                  className="text-gray-500 font-bold mb-2 mr-5 w-30"
                >
                  File
                </label>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => removeFileField(index)}
                    // className={classes.deleteButton}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </div>
              <input
                type="file"
                id={`file-${index}`}
                name={`file-${index}`}
                {...register(`file-${index}`, {
                  // required: 'File is required',
                  validate: {
                    allowedFormats: (value) =>
                      [
                        'csv',
                        'doc',
                        'docx',
                        'eml',
                        'epub',
                        'gif',
                        'htm',
                        'html',
                        'jpeg',
                        'jpg',
                        'json',
                        'log',
                        'mp3',
                        'msg',
                        'odt',
                        'ogg',
                        'pdf',
                        'png',
                        'pptx',
                        'ps',
                        'psv',
                        'rtf',
                        'tab',
                        'tff',
                        'tif',
                        'tiff',
                        'tsv',
                        'txt',
                        'wav',
                        'xls',
                        'xlsx',
                      ].includes(value[0].type.split('/')[1]),
                  },
                })}
                onChange={(e) =>
                  setFiles({ ...files, [index]: e.target.files[0] })
                }
                className="block w-full py-2 text-gray-700 h-10 "
              />
              <div>
                <div className="block w-full">
                  <label
                    htmlFor={`url_key_${index}`}
                    className="text-gray-500 font-bold mb-2 mr-5 w-30 flex-shrink-0"
                  >
                    Description
                  </label>
                  {formState.errors &&
                    formState.errors[`file-description-${index}`] && (
                      <p className="mt-1 mb-2 text-red-500">
                        {formState.errors[`file-description-${index}`].message}
                      </p>
                    )}
                  <input
                    type="text"
                    id={`file-description-${index}`}
                    name={`file-description-${index}`}
                    className="block block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-r-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 disabled:opacity-50"
                    placeholder="product introduction, feature sheet, product deck and etc"
                    {...register(`file-description-${index}`, {
                      // required: 'File description is required',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

        <div
          className="w-full h-20 bg-gray-200 rounded-md flex justify-center items-center cursor-pointer"
          onClick={addFileField}
        >
          <div className="w-12 h-12 bg-blue-500 rounded-full flex justify-center items-center">
            <div className="text-white text-3xl flex items-center justify-center">
              <Tooltip title="Add related files">
                <IconButton>
                  <Add />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-center py-2 mb-6">
        <button
          type="submit"
          className="px-12 py-4 bg-red-600 rounded-md text-white font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default CaseForm;
