import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, initializeCart } from '../../../redux/cartSlice';
import CartCard from './CartCard';
import DownloadCSVButton from './DownloadCSVButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import LinkIcon from '@mui/icons-material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import PhoneIcon from '@mui/icons-material/Phone';
import axios from 'axios';
import Config from '../../../Constant';

const Cart = ({ userId, caseId }) => {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch initial data when the component mounts
    const fetchCartData = async () => {
      try {
        const response = await axios.get(
          Config.PROSPECT.LOAD_CART_URI + `?user_id=${userId}&case_id=${caseId}`
        ); // Adjust the API endpoint as needed
        // Dispatch an action to add the retrieved cart items to the Redux store
        dispatch(initializeCart(response.data.cartItems));
      } catch (error) {
        // Handle error, dispatch an action if necessary
        // dispatch(cartLoadError(error));
      }
    };

    fetchCartData(); // Call the fetch function
  }, []); // Empty dependency array ensures this effect runs once after initial render

  return (
    <div className="cart">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <h2 className="cart-title">Cart</h2>
        </Grid>
        <Grid item>
          <DownloadCSVButton cartItems={cartItems} />
        </Grid>
      </Grid>

      {Object.keys(cartItems).map((prospectId) => (
        <Accordion key={prospectId}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              {cartItems[prospectId][0].title}
              {cartItems[prospectId][0].person.organization && (
                <span className="ml-8 icon-links space-x-4">
                  {cartItems[prospectId][0].person.organization.website_url && (
                    <a
                      href={
                        cartItems[prospectId][0].person.organization.website_url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkIcon />
                    </a>
                  )}
                  {cartItems[prospectId][0].person.organization
                    .linkedin_url && (
                    <a
                      href={
                        cartItems[prospectId][0].person.organization
                          .linkedin_url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon />
                    </a>
                  )}
                  {cartItems[prospectId][0].person.organization
                    .facebook_url && (
                    <a
                      href={
                        cartItems[prospectId][0].person.organization
                          .facebook_url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                  )}
                  {cartItems[prospectId][0].person.organization
                    .sanitized_phone && (
                    <a
                      href={`tel:${cartItems[prospectId][0].person.organization.sanitized_phone}`}
                    >
                      <PhoneIcon />
                    </a>
                  )}
                </span>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="cart-persons">
              {cartItems[prospectId].map(
                ({ person, email_content, email_subject }) => (
                  <div key={person.email} className="my-4">
                    <CartCard
                      person={person}
                      prospectId={prospectId}
                      email_content={email_content}
                      email_subject={email_subject}
                      castId={caseId}
                    />
                  </div>
                )
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Cart;
