import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../components/stream-app/Header"
import { Navigate } from 'react-router-dom'
import Footer from "../components/front-page/Footer"
import Contact from "../components/front-page/Contact"

const ContactPage = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div className="text-center">Loading ...</div>;
  }

  return (
    isAuthenticated ? ( <>
                          <Header/> 
                          
                          <main className="bg-gray-100  w-full">
                            <section className="container mx-auto py-1">
                                <Contact />
                            </section>
                          </main>

                          <Footer /> 
                        </>
                     ) 
                    : <Navigate to="/" />
  )
};

export default ContactPage;