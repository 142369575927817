import config from '../../Constant';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const contentRef = useRef();
  const emailRef = useRef();
  const topicRef = useRef();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailRef.current.value !== '' && contentRef.current.value !== '') {
      const requestOptions = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          to: 'admin@vona.ai',
          email: emailRef.current.value,
          topic: topicRef.current.value,
          content: contentRef.current.value,
        }),
      };
      fetch(config.EMAIL.CONTACT_ENDPOINT, requestOptions)
        .then((res) => res.json())
        .then((data) => console.log('email send response: ', data))
        .catch((e) => console.log('error send email ', e));
    } else {
      alert('both email and content cannot be null');
    }
  };

  return (
    <div className="container my-28">
      <h2 className="text-[32px] mb-8 text-center font-heading font-bold">
        {t('Contact US')}
      </h2>
      <div className="w-full max-w-2xl mx-auto mb-12">
        <form>
          <div className="flex mb-4 -mx-2">
            <div className="w-1/2 px-2">
              <input
                ref={emailRef}
                className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                type="email"
                placeholder={t('Email')}
              />
            </div>
            <div className="w-1/2 px-2">
              <select
                ref={topicRef}
                className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
              >
                <option>-- {t('Select topic')}--</option>
                <option>{t('Product Question')}</option>
                <option>POC {t('Request')}</option>
                <option>{t('Other')}</option>
              </select>
            </div>
          </div>
          <div className="mb-4">
            <textarea
              ref={contentRef}
              className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
              type="password"
              placeholder={t('Write something')}
              rows="5"
            ></textarea>
          </div>
          <div className="mt-8 flex items-center justify-around sm:justify-start sm:space-x-8">
            <button
              className="primary-button inline-block w-full py-4 px-8 leading-none"
              onClick={handleSubmit}
            >
              {t('Submit')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
