import React from 'react';
import { useDispatch } from 'react-redux';
import { setActiveCase } from '../../../redux/workAreaSlice';

const SelectCase = ({ cases }) => {
  const dispatch = useDispatch();

  const handleCaseSelect = (caseItem) => {
    console.log('caseItem is', caseItem);
    dispatch(setActiveCase(caseItem));
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-4">Select a case</h2>
      <ul className="list-disc pl-6">
        {cases.map((caseItem) => (
          <li
            key={caseItem.caseId}
            className="cursor-pointer text-blue-500 hover:text-blue-700 mb-2"
            onClick={() => handleCaseSelect(caseItem)}
          >
            {caseItem.caseName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectCase;
