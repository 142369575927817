// store.js
import { configureStore } from '@reduxjs/toolkit';
import industryReducer from './industrySlice';
import cartReducer from './cartSlice';
import workAreaReducer from './workAreaSlice';

const store = configureStore({
  reducer: {
    cart: cartReducer, // Include the cart reducer in your Redux store
    industry: industryReducer,
    workArea: workAreaReducer,
  },
});

export default store;
