import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import config from '../../../Constant';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles
import { useDispatch } from 'react-redux'; // Import useDispatch
import { addProspectToIndustry } from '../../../redux/industrySlice'; // Import your action creator

const useStyles = makeStyles((theme) => ({
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    fontSize: '2rem',
    width: '2rem',
    height: '2rem',
    cursor: 'pointer', // Add cursor pointer
    marginLeft: theme.spacing(1), // Add spacing between icons
  },
}));

const GenerateProspectListBySegmentButton = ({ userId, caseId, segmentId }) => {
  const [status, setStatus] = useState('initial');
  const dispatch = useDispatch(); // Initialize dispatch

  const handleGenerate = async () => {
    try {
      setStatus('submitted'); // Call the callback function to update the parent component
      const response = await axios.post(
        config.PROSPECT.BUILD_PROSPECT_BY_SEGMENT_URI,
        {
          userId,
          caseId,
          segmentId,
        }
      );

      // Check for errors in the response (assuming the response is an object with an 'error' property)
      if (response.data.error) {
        console.error('Error from server:', response.data.error);
      } else {
        // Assuming the response contains the generated companies in a different format
        const generatedCompanies = response.data.data.companies;
        const prospectIds = response.data.data.propsect_ids;

        // Format the data to match your Redux state structure
        const formattedCompanies = generatedCompanies.map((company) => ({
          company_name: company.name,
          prospect_reason: company.reason,
          company_domain: company.domain_name,
          prospect_id: company.prospect_id,
          // Add other fields as needed
        }));

        dispatch(
          addProspectToIndustry({
            segmentId,
            newProspectData: formattedCompanies,
            newProspectIds: prospectIds,
          })
        );

        setStatus(''); // Call the callback function to update the parent component
      }
    } catch (error) {
      console.error('Error generating prospect list:', error);
    }
  };
  const classes = useStyles(); // Initialize the styles

  return status === 'submitted' ? (
    <div className="text-blue-500"> Processing ... </div>
  ) : (
    <LightbulbIcon
      className={classes.iconButton}
      fontSize="2rem" // Adjust the font size to match other icons
      style={{ zIndex: 9999 }}
      onClick={(evt) => {
        evt.preventDefault();
        // evt.stopPropagation(); // Stop event propagation here

        console.log('GenerateProspectListBySegmentButton is clicked');
        handleGenerate();
      }}
    />
  );
};

export default GenerateProspectListBySegmentButton;
