import React from 'react';
import Header from '../components/front-page/Header';
import Hero from '../components/front-page/Hero';
import Footer from '../components/front-page/Footer';
// import BottomCTA from '../components/front-page/BottomCTA';
import Contact from '../components/front-page/Contact';
import Widget from '../components/front-page/widget/Widget';
import CustomerLogo from '../components/front-page/CustomerLogo';
import Features from '../components/front-page/Features';
// import Benefits from '../components/front-page/Benefits';
// import Switcher from '../locales/Switcher';

function FrontPage() {
  const handleScollViewClick = (divId) => {
    const element = document.getElementById(divId);
    element.scrollIntoView();
  };

  return (
    <div className="bg-gray-100 text-gray-800 min-h-screen font-sans">
      {/* Header section */}
      <div className="py-16">
        <Header
          handlePriceClick={() => handleScollViewClick('contactDiv')}
          handleProductClick={() => handleScollViewClick('productDiv')}
          handleCustClick={() => handleScollViewClick('custDiv')}
          handleContactClick={() => handleScollViewClick('contactDiv')}
          hideSignIn={true}
        />
      </div>

      {/* Hero section with light gray background */}
      <div className="bg-gradient-to-r from-white to-gray-300 py-16">
        <Hero
          handleGetItClick={() => handleScollViewClick('contactDiv')}
          // handleVideoClick={() => handleScollViewClick('contactDiv')}
        />
      </div>

      {/* Rest of the sections */}
      <div id="productDiv" className="text-gray-800 py-16">
        <Features handleSignUp={() => handleScollViewClick('contactDiv')} />
      </div>
      <div id="custDiv" className="bg-gray-200 text-gray-800 py-16">
        <CustomerLogo />
      </div>
      <div id="contactDiv" className="bg-gray-100 text-gray-800 py-16">
        <Contact />
      </div>
      <Widget />
      <Footer />
    </div>
  );
}

export default FrontPage;
