import { useState, useEffect } from 'react';
import config from '../../../Constant';

import { useDispatch } from 'react-redux'; // Import useDispatch hook
import { setIndustries } from '../../../redux/industrySlice'; // Import the setIndustries action

const useIndustryList = (userId, caseId, timeout = 60000) => {
  const [isFetching, setIsFetching] = useState(true);
  const dispatch = useDispatch(); // Define dispatch from react-redux

  useEffect(() => {
    if (!caseId) {
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          config.INDUSTRY.GET_INDUSTRY_URI +
            `?userId=${userId}&caseId=${caseId}`
        );
        const data = await response.json();
        // const data = raw.split('\n');
        // if (data.length > 0) {
        dispatch(setIndustries(data));
        // }
        setIsFetching(false);
      } catch (error) {
        console.error('Error fetching industries:', error);
      }
    };

    fetchData();

    return () => {
      setIsFetching(false); // Stop fetching when component unmounts
    };
  }, []);

  return { isFetching };
};

export default useIndustryList;
